import React from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { formatMoney } from '../../helpers';
import { useIsMobile } from '../../hooks';
import { collectionPriorityColors } from '../../constants';

const CollectionSummaryBarChart = ({ chartData, title, legend, isMoney }) => {
  const isMobile = useIsMobile();
  const moneyFormatter = (value) => (isMoney ? `$${formatMoney(value)}` : formatMoney(value));

  return (
    <Grid
      xs={12}
      sx={{ borderRadius: 2, px: 4, py: 2, bgcolor: 'white' }}
      height={270}
      marginX={{
        xs: 0,
        md: 2,
      }}
      marginY={{
        xs: 2,
        md: 0,
      }}
    >
      <Typography textAlign="center" variant="h6" width="100%">
        {title}
      </Typography>
      <ResponsiveContainer>
        <BarChart
          height={300}
          data={chartData}
          overflow="visible"
          padding={{
            top: 10,
            right: 10,
            left: 30,
            bottom: 5,
          }}
        >
          <XAxis
            dataKey="name"
            interval={0}
            style={{
              fontSize: '9px',
              flexWrap: 'wrap',
            }}
            dy={isMobile ? 20 : 0}
            angle={isMobile ? -45 : 0}
          />
          <YAxis
            tickFormatter={moneyFormatter}
            style={{
              fontSize: '9px',
              flexWrap: 'wrap',
            }}
          />
          {legend && (
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              align="center"
              paddingBottom={10}
            />
          )}
          <Tooltip formatter={moneyFormatter} />
          {range(1, 6).map((priority) => (
            <Bar
              key={priority}
              dataKey={`priority${priority}`}
              name={<Typography>Prioridad {priority}</Typography>}
              stackId="a"
              fill={collectionPriorityColors[priority]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};

CollectionSummaryBarChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      dateGroup: PropTypes.number,
      priority1: PropTypes.number,
      priority2: PropTypes.number,
      priority3: PropTypes.number,
      priority4: PropTypes.number,
      priority5: PropTypes.number,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.bool,
  isMoney: PropTypes.bool,
};

CollectionSummaryBarChart.defaultProps = {
  legend: false,
  isMoney: false,
};

export default CollectionSummaryBarChart;

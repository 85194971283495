import React from 'react';
import PropTypes from 'prop-types';
import RoundedWhiteBox from '../boxes/RoundedWhiteBox';
import { FingoDataGrid } from '../dataGrids';
import { PRIORITY_COLUMNS } from '../../constants/collection-summary';

const CollectionSummaryDataGrid = ({ collectionManagersByPriority }) => (
  <RoundedWhiteBox>
    <FingoDataGrid
      rows={collectionManagersByPriority}
      columns={PRIORITY_COLUMNS}
      getRowId={(row) => row.collectionPriority}
      rowHeight={30}
      serverFilters={false}
      hideFooter
    />
  </RoundedWhiteBox>
);

CollectionSummaryDataGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  collectionManagersByPriority: PropTypes.array.isRequired,
};

export default CollectionSummaryDataGrid;

import React, { useState, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { formatGraphQlDate, formatMonthYear } from '../../helpers';
import { useBooleanState } from '../../hooks';
import { CURRENT_BEHAVIOR_DETAILS } from '../../graphql';
import { FingoDialog } from '../dialogs';
import CurrentBehaviorHeader from './currentBehaviorHeader';
import CurrentBehaviorDetails from './currentBehaviorDetails';

const CurrentBehaviorComponent = ({ masterEntityId, label, disabled }) => {
  const [selectedBehaviorId, setSelectedBehaviorId] = useState(null);
  const [open, setOpen] = useBooleanState();
  const [loadCurrentBehavior, { data, loading, error }] = useLazyQuery(
    CURRENT_BEHAVIOR_DETAILS,
    {
      variables: { masterEntityId },
      fetchPolicy: 'network-only',
      onCompleted: ({ getMasterEntity }) => {
        const BehaviorsList = getMasterEntity.currentBehaviors;
        setSelectedBehaviorId(BehaviorsList[BehaviorsList.length - 1].id);
        setOpen(true);
      },
    },
  );
  const selectedBehavior = useMemo(() => (data?.getMasterEntity.currentBehaviors.find(
    (dicom) => dicom.id === selectedBehaviorId,
  )), [selectedBehaviorId]);
  const availableOptions = data?.getMasterEntity.currentBehaviors.map((behavior) => ({
    timestamp: moment(behavior.createdAt),
    label: formatGraphQlDate(moment(behavior.createdAt)),
    value: behavior.id,
    key: `behavior-${behavior.id}`,
  })).sort((a, b) => a.timestamp.diff(b.timestamp));
  const dateInform = (
    selectedBehavior?.dateInform && formatMonthYear(moment(selectedBehavior?.dateInform))
  );
  return (
    <>
      <LoadingButton
        onClick={loadCurrentBehavior}
        loading={loading}
        variant="contained"
        disabled={error || disabled}
        size="small"
      >
        {error ? 'Error ' : ''}{label}
      </LoadingButton>
      <FingoDialog
        title={`Comportamiento Vigente de ${data?.getMasterEntity.name}`}
        open={open}
        handleClose={setOpen}
        maxWidth="lg"
        fullWidth
      >
        <CurrentBehaviorHeader
          selectedBehaviorId={selectedBehaviorId}
          availableOptions={availableOptions}
          setSelectedBehaviorId={setSelectedBehaviorId}
          totalDebtAmount={selectedBehavior?.totalDebt ?? 0}
          dateInform={dateInform}
        />
        <CurrentBehaviorDetails
          selectedBehavior={selectedBehavior}
        />
      </FingoDialog>
    </>
  );
};

CurrentBehaviorComponent.propTypes = {
  label: PropTypes.string,
  masterEntityId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CurrentBehaviorComponent.defaultProps = {
  disabled: false,
  label: 'Detalle',
};

export default CurrentBehaviorComponent;

import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const CollectionSummaryCard = ({ label, percent, percentColor, amount, money }) => (
  <Stack direction="row" spacing={1} width="100%">
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="column"
      width="100%"
      sx={{ bgcolor: 'white', borderRadius: 2, p: 1.5 }}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="subtitle2" color={percentColor}>
        {percent}
      </Typography>
      <Typography variant="h6">
        {money && '$'}
        <CountUp separator="." end={amount} duration={1} />
      </Typography>
    </Stack>
  </Stack>
);

CollectionSummaryCard.propTypes = {
  label: PropTypes.string.isRequired,
  percent: PropTypes.string,
  percentColor: PropTypes.string,
  amount: PropTypes.number.isRequired,
  money: PropTypes.bool,
};

CollectionSummaryCard.defaultProps = {
  money: true,
  percent: null,
  percentColor: null,
};

export default CollectionSummaryCard;

import React from 'react';
import PropTypes from 'prop-types';
import currentBehaviorArrayTransformer from './currentBehaviorArrayTransformer';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import { CurrentBehaviorDetailsColumns } from '../../constants';

const CurrentBehaviorDetails = ({ selectedBehavior }) => {
  const details = selectedBehavior?.currentBehaviorDetails;
  const { rowsArray, columnsArray } = currentBehaviorArrayTransformer(details);
  return (
    <FingoDataGrid
      rows={rowsArray}
      columns={CurrentBehaviorDetailsColumns(columnsArray)}
      serverFilters={false}
      hideFooter
    />
  );
};

CurrentBehaviorDetails.propTypes = {
  selectedBehavior: PropTypes.shape({
    id: PropTypes.string,
    currentBehaviorDetails: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      date: PropTypes.string,
      directCastDebt: PropTypes.number,
      indirectCastDebt: PropTypes.number,
      comercialDebt: PropTypes.number,
      consumerCreditDebt: PropTypes.number,
      contingentCreditDebt: PropTypes.number,
      mortgageCreditDebt: PropTypes.number,
      directExpired3Months3YearsDebt: PropTypes.number,
      directValidDebt: PropTypes.number,
      indirectExpiredDebt: PropTypes.number,
      indirectValidDebt: PropTypes.number,
      financialDebt: PropTypes.number,
      leasingDebt: PropTypes.number,
      moroseDebt: PropTypes.number,
      moroseLeasingDebt: PropTypes.number,
      operationCPactDebt: PropTypes.number,
      directExpiredDebt: PropTypes.number,
      currentCreditLine: PropTypes.number,
      comercialExpiredAmountDebt: PropTypes.number,
      comercialValidAmountDebt: PropTypes.number,
      consumeCreditNumberCreditors: PropTypes.number,
      numberConsumeCreditEntities: PropTypes.number,
    })),
  }),
};

CurrentBehaviorDetails.defaultProps = {
  selectedBehavior: {},
};

export default CurrentBehaviorDetails;
